import {ModelBase} from './model-base';
import {getId, getIdT, Id, Modify} from '../utils/type.utils';
import {
    ActionReadSerializer,
    ActionReadSerializerWithTask,
    EventActionReadSerializer,
    DiagnosisActionReadSerializer,
    LabOrderReadSerializer,
    LabTestActionReadSerializer,
    OrderReviewActionReadSerializer,
    OtherOrderReadSerializer,
    PlanActionSerializer,
    TreatmentActionReadSerializer,
    VitalReviewActionReadSerializer,
    GoalReadSerializer,
    NegationReasonReadSerializer,
    WoundAssessmentCreateSerializer,
    DressingOrderReadSerializer,
} from '../@core/api.service';
import {ICDCode} from './ICD-code';
import {Diagnosis} from './diagnosis';
import {SmartLinkDefinitions, SmartLinkTypeKey} from '../definitions/definitions';
import {CONSTANTS} from '../@core/constants';
import {
    Allergy,
    CriticalVital,
    Immunization,
    LabTest,
    Order,
    PatientEvent,
    Procedure,
    RXOrder,
    Task,
    TreatmentEntry,
    ImagingResult,
    ClinicalResult,
    Wound,
} from './models';
import {DISEASE_STATUSES} from '../@core/disease_statuses';

export type EntryActionType =
    'REVIEW' |
    'COMMENT' |
    'LAB_ORDER' |
    'RX_ORDER' |
    'OTHER_ORDER' |
    'UPDATE_ICD' |
    'DISCONTINUE' |
    'NO_ACTION' |
    'ACTIVE' |
    'ACUTE' |
    'RESOLVED' |
    'DORMANT' |
    'INSUFFICIENT' |
    'ADD' |
    'HEALTH_CONCERN' |
    'GOAL' |
    'NEGATION_REASON' |
    'WOUND_ASSESSMENT' |
    'DRESSING_ORDER';

export interface EntryNote<T = any> {
    entry: T;
    include: boolean;
    actions: EntryAction[];
}

export const ENTRY_EDIT_PREFIX = '[EDIT_ENTRY]';

export type DiseaseStatus = keyof typeof DISEASE_STATUSES;

export const DISEASE_STATUS_CHOICES = Object.entries(DISEASE_STATUSES).map(([id, name]) => ({id, name}));

// export class EntryAction extends ModelBase implements ActionReadSerializer {
// export class EntryAction extends ModelBase implements ActionReadSerializerWithTask {
export class EntryAction extends ModelBase implements Modify<ActionReadSerializer, {lab_test: LabTest; treatment_history: TreatmentEntry}>, Modify<ActionReadSerializerWithTask, {lab_test: LabTest; treatment_history: TreatmentEntry}>, Modify<LabTestActionReadSerializer, {lab_test: LabTest}>, Modify<TreatmentActionReadSerializer, {treatment_history: TreatmentEntry}>, EventActionReadSerializer, VitalReviewActionReadSerializer, DiagnosisActionReadSerializer, OrderReviewActionReadSerializer {
    id: number;
    hash: string;
    hidden_in_plan: boolean;
    sort: number;

    // ActionReadSerializerWithTask
    task: Task; // TaskActionSerializer

    // ENTRY
    lab_test: LabTest;
    treatment_history: TreatmentEntry;
    event: PatientEvent;
    order: Order;
    vital: CriticalVital;
    diagnosis: Diagnosis;
    procedure: Procedure;
    imaging_result: ImagingResult;
    clinical_test_result: ClinicalResult;
    patient_allergy: Allergy;
    immunization: Immunization;
    wound: Wound;

    // A/P DX
    icd: ICDCode;

    // ACTION TYPE
    action: EntryActionType;

    // ACTION CONTENT = DATA
    comments: string;
    icd_update: ICDCode;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;
    goal: GoalReadSerializer;
    negation_reason: NegationReasonReadSerializer;
    wound_assessment: WoundAssessmentCreateSerializer;
    dressing_order: DressingOrderReadSerializer;

    // ACTION RESULT
    resolved_at: Date | string;
    resolved_by: number;

    // ADDITIONAL PROPERTY
    disease_status: DiseaseStatus;

    private _entryType: SmartLinkTypeKey;
    private _data: any;
    private _entry: any;

    // ENTRY TYPE
    get entryType(): SmartLinkTypeKey {
        if (this._entryType) return this._entryType;

        if (this.lab_test) return 'LAB_TEST';
        if (this.treatment_history) return 'MEDICATION';
        if (this.event) return 'EVENT';
        if (this.order) return 'OTHER_ORDER';
        if (this.vital) return 'VITAL';
        if (this.diagnosis || this.action === 'ADD') return 'DIAGNOSIS';
        if (this.procedure) return 'PROCEDURE';
        if (this.imaging_result) return 'IMAGING_RESULT';
        if (this.clinical_test_result) return 'CLINICAL_RESULT';
        if (this.patient_allergy) return 'ALLERGY';
        if (this.immunization) return 'IMMUNIZATION';
        if (this.wound) return 'WOUND';
        if (this.icd || typeof this.sort === 'number') return 'ICD_CODE'; // mainly including sort for handling null ICDs after reloading actions under A/P general assessment

        return null;
    }

    set entryType(x) {
        let _entry: any;
        if (this._entryType !== x) {
            _entry = this.entry;
            this.entry = null;
        }

        this._entryType = x;

        if (_entry) {
            this.entry = _entry;
        }
    }

    get type() {
        return this.action;
    }

    set type(x) {
        this.action = x;
    }

    get dataKey() {
        return this.action ? EntryAction.dataKeyMap[this.action] : '_data';
    }

    get data(): any {
        return this[this.dataKey];
    }

    set data(x) {
        (this[this.dataKey] as any) = x;
    }

    get entryKey() {
        return this.entryType ? SmartLinkDefinitions[this.entryType].actionEntryKey : '_entry';
    }

    get entry(): any {
        return this[this.entryKey];
    }

    set entry(x) {
        this[this.entryKey as any] = x;
    }

    get resolved() {
        return !!this.resolved_at;
    }

    get isSelfSufficientOrder() {
        return ['RX_ORDER', 'LAB_ORDER', 'OTHER_ORDER'].includes(this.type);
    }

    get hasExtraComments() {
        return this.type === 'ADD';
    }

    assignBaseProperties(x: Partial<EntryAction>) {
        super.assign(x);

        this.setAccessor('icd', ICDCode);
        this.setAccessor('icd_update', ICDCode);
        this.setAccessor('lab_test', LabTest);
        this.setAccessor('treatment_history', TreatmentEntry);
        this.setAccessor('event', PatientEvent);
        this.setAccessor('order', Order);
        this.setAccessor('vital', CriticalVital);
        this.setAccessor('diagnosis', Diagnosis);
        this.setAccessor('procedure', Procedure);
        this.setAccessor('imaging_result', ImagingResult);
        this.setAccessor('clinical_test_result', ClinicalResult);
        this.setAccessor('patient_allergy', Allergy);
        this.setAccessor('immunization', Immunization);
        this.setAccessor('wound', Wound);

        if (this.task) this.setAccessor('task', Task);
    }

    assign(x: Partial<EntryAction>) {
        this.assignBaseProperties(x);

        this.setAccessor('rx_order', RXOrder);
    }

    getRelatedIcdId(): number {
        if (this.action === 'HEALTH_CONCERN') return null; // TODO: revisit this - we didn't want health concerns to create a separate A/P entry

        let icd: number = getId(this.icd);
        if (!icd) icd = getId(this.icd_update);
        if (!icd) {
            switch (this.action) {
                case 'UPDATE_ICD':
                case 'ADD':
                    icd = getId(this.data);
                    break;
                case 'LAB_ORDER':
                case 'OTHER_ORDER':
                case 'RX_ORDER':
                    icd = getId(this.data?.related_icd_diagnoses && this.data.related_icd_diagnoses[0]);
                    break;
            }
        }
        if (!icd) icd = getId(this.entry?.icd);
        if (CONSTANTS.NO_ICD_CODE_IDS.includes(icd)) icd = null;

        return icd || null;
    }

    getValue(): PlanActionSerializer {
        const entry = this.entry;
        const entryId = getIdT(entry) as Id;
        let entryData: any = entryId;
        if (entryId) {
            if (parseInt(entryId as any) < 0) {
                entryData = {
                    ...(entry.getSerialized ? entry.getSerialized() : entry),
                    hash: (-entryId).toString(36),
                    patient: getId(entry.patient),
                    __newEntry: true,
                };
            } else if (typeof entryId === 'string' && entryId.startsWith(ENTRY_EDIT_PREFIX)) {
                entryData = {
                    ...(entry.getSerialized ? entry.getSerialized() : entry),
                    id: parseInt(entryId.slice(ENTRY_EDIT_PREFIX.length)),
                    __editEntry: true,
                };
            }
        }
        const v = { // TODO: remove any
            id: this.id,
            hash: this.hash,
            action: this.action,
            // icd: this.getRelatedIcdId(),
            sort: this.sort,
            hidden_in_plan: !!this.hidden_in_plan,
            disease_status: this.type === 'COMMENT' ? this.disease_status : null,
            [this.entryKey]: entryData,
            [this.dataKey]: this.data,
            icd: getId(this.icd),
            icd_update: getId(this.icd_update),
        } as any;
        if (this.hasExtraComments) v.comments = this.comments;
        return v;
    }

    static readonly dataKeyMap: {[K in EntryActionType]: keyof EntryAction} = {
        REVIEW: 'comments',
        COMMENT: 'comments',
        DISCONTINUE: 'comments',
        NO_ACTION: 'comments',
        UPDATE_ICD: 'icd_update',
        LAB_ORDER: 'lab_order',
        RX_ORDER: 'rx_order',
        OTHER_ORDER: 'other_order',
        ACTIVE: 'comments',
        ACUTE: 'comments',
        RESOLVED: 'comments',
        DORMANT: 'comments',
        INSUFFICIENT: 'comments',
        ADD: 'icd',
        HEALTH_CONCERN: 'comments',
        GOAL: 'goal',
        NEGATION_REASON: 'negation_reason',
        WOUND_ASSESSMENT: 'wound_assessment',
        DRESSING_ORDER: 'dressing_order',
    };

    static hasCarePlan(action: {action?: string}) {
        return ['COMMENT', 'ADD'].includes(action.action);
    }
}
