export const ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES = {
    ACTIVE_COVERAGE: 'Active Coverage',
    INACTIVE_COVERAGE: 'Inactive Coverage',
    CANNOT_PROCESS: 'Cannot Process',
    UNKNOWN: 'Unknown',
    ERROR: 'Error',
    GENERATE_ERROR: 'Error Generating Inquiry',
    CLEARINGHOUSE_ERROR: 'Clearing House Error',
    PARSE_ERROR: 'Error Processing Response',
    SUBSCRIBER_NOT_FOUND: 'Subscriber Not Found',
    INVALID_OR_MISSING_DATA: 'Invalid or Missing Data',
};
