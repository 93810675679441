export const SERVICE_LINE_STATUSES = {
    PAID: 'Paid',
    PAID_BY_PRIMARY: 'Paid by Primary',
    PARTIALLY_PAID: 'Partially Paid',
    OVERPAID: 'Overpaid',
    ALLOWED: 'Allowed',
    DENIED_MISSING_INFO: 'Denied - Missing info',
    DENIED_MANAGED_CARE: 'Denied - Managed Care',
    DENIED_INCORRECT_PAYER: 'Denied - Incorrect Payer',
    DENIED_INCORRECT_POS: 'Denied - Incorrect POS',
    DENIED_ENROLLED_IN_HOSPICE: 'Denied - Enrolled in Hospice',
    DENIED_TRANSFER_TO_OTHER_PAYER: 'Denied - Transfer to other payer',
    DENIED: 'Denied',
    ERROR: 'Error',
};
