import {
    APIService,
    ClaimHistoryEventSerializer,
    ClaimItemSerializer,
    ClaimPatientSerializer,
    ClaimSerializer,
    ClaimSimpleSerializer,
    ClaimTaskSerializer,
    FacilityAddressSerializer,
    PayerAltNameDetailSerializer,
    UserSearchSerializer,
} from '../@core/api.service';
import {CLAIM_STATUSES} from '../@core/claim_statuses';
import {GetterCache, MethodCache} from '../utils/accessor.utils';
import {Human} from './human';
import {PLACE_OF_SERVICE_CONSTANTS} from '../@core/place_of_service_constants';
import {PatientInsurance} from './patient-insurance';
import {InsuranceEligibility} from './insurance-eligibility';
import {getStateColor} from '../utils/color.utils';
import {contextColors} from '../@theme/theme-colors';
import {ModelBase, ModelBaseListAccessor} from './model-base';
import {getMultilineFullAddress} from '../utils/address.utilts';
import {HistoryOfPresentIllness} from '../@core/api.task_types';
import {EntryAction} from './entry-note';
import {Remittance} from './remittance';
import {SERVICE_LINE_STATUSES} from '../@core/service_line_statuses';
import {NumberKeys, getId} from '../utils/type.utils';
import {of} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

export type ClaimItemStatus = keyof typeof SERVICE_LINE_STATUSES;
export type ClaimStatus = keyof typeof CLAIM_STATUSES;

export interface ClaimStatusGroup {
    name: string;
    color: string;
    statuses?: ClaimStatus[];
    hasResponseCols?: boolean;
}

export const getClaimStatusGroups: () => ClaimStatusGroup[] = () => [
    {
        name: 'All',
        color: contextColors.primary,
    },
    {
        name: 'Unsubmitted',
        color: contextColors.warning,
        statuses: ['DRAFT', 'HOLD_FOR_FUTURE', 'READY_TO_BILL'],
        hasResponseCols: false,
    },
    {
        name: 'Pending',
        color: getStateColor(.5, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]),
        statuses: ['QUEUED_FOR_SUBMISSION', 'UPLOADED', 'ACCEPTED', 'PENDING', 'COMPLETED'],
        hasResponseCols: false,
    },
    {
        name: 'Successful',
        color: contextColors.success,
        statuses: ['ALLOWED', 'PARTIALLY_PAID', 'PAID_BY_PRIMARY', 'PAID', 'OVERPAID'],
    },
    {
        name: 'Rejected',
        color: contextColors.danger,
        statuses: [
            'REJECTED',
            'DENIED_MISSING_INFO',
            'DENIED_INCORRECT_PAYER',
            'DENIED_INCORRECT_POS',
            'DENIED_MANAGED_CARE',
            'ERROR',
        ],
    },
    {
        name: 'Denied',
        color: contextColors.danger,
        statuses: [
            'DENIED',
            'DENIED_ENROLLED_IN_HOSPICE',
            'DENIED_TRANSFER_TO_OTHER_PAYER',
            'UNBILLABLE',
        ],
    },
];

export class ClaimItem extends ModelBase implements ClaimItemSerializer {
    id: number;
    allowed_amount: number;
    charge: number;
    coins: number;
    coins_paid: number;
    coins_paid_date: Date | string;
    copay: number;
    copay_paid: number;
    copay_paid_date: Date | string;
    deduct: number;
    deduct_paid: number;
    deduct_paid_date: Date | string;
    diag_codes: any;
    modifier_codes: any;
    other_reductions_by_payer: number;
    paid: number;
    pos: string;
    proc_code: string;
    secondary_denial: boolean;
    secondary_insurance: boolean;
    service_from: Date | string;
    sort: number;
    status: ClaimItemStatus;
    units: string;
    denial_reason: any;

    @GetterCache()
    get codeMerged() {
        return [this.proc_code, ...(this.modifier_codes || [])].join('-');
    }

    @GetterCache()
    get statusText() {
        return this.status && SERVICE_LINE_STATUSES[this.status];
    }

    @GetterCache()
    get statusColor() {
        return ClaimItem.getStatusColor(this.status);
    }

    @GetterCache()
    get paidSum() {
        return (this.paid || 0) + (this.deduct_paid || 0) + (this.coins_paid || 0) + (this.copay_paid || 0);
    }

    @GetterCache()
    get expectedSum() {
        return this.allowed_amount && this.allowed_amount - (this.other_reductions_by_payer || 0);
    }

    @GetterCache()
    get paidRatio() {
        return this.allowed_amount && this.paidSum / this.expectedSum;
    }

    @GetterCache()
    get paidColor() {
        return getStateColor(this.paidRatio, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
    }

    static getStatusColor(status: ClaimItemStatus) {
        return Claim.getStatusColor(status);
    }
}

export class Claim extends ModelBase implements ClaimSerializer {
    id?: number;
    claim_ref: string;
    provider: UserSearchSerializer;
    facility?: FacilityAddressSerializer;
    invalidity_reasons?: any;
    diag_codes?: any;
    patient?: ClaimPatientSerializer;
    tasks?: Array<ClaimTaskSerializer>;
    status?: ClaimStatus;
    type?: string;
    created_at?: Date | string;
    modified_at?: Date | string;
    sbr_id?: string;
    pat_last_name?: string;
    pat_middle_name?: string;
    pat_first_name?: string;
    pat_birthdate?: Date | string;
    pat_sex?: string;
    pat_suffix?: string;
    pat_phone?: string;
    admission_from?: Date | string;
    sbr_last_name?: string;
    sbr_middle_name?: string;
    sbr_first_name?: string;
    sbr_suffix?: string;
    sbr_phone?: string;
    pat_address?: string;
    pat_city?: string;
    pat_state?: string;
    pat_zip?: string;
    pat_rel?: string;
    sbr_address?: string;
    sbr_city?: string;
    sbr_state?: string;
    sbr_zip?: string;
    sbr_birthdate?: Date | string;
    sbr_sex?: string;
    bill_tin?: string;
    bill_tin_type?: string;
    bill_name?: string;
    bill_add1?: string;
    bill_city?: string;
    bill_state?: string;
    bill_zip?: string;
    bill_npi?: string;
    bill_taxonomy_code: string;
    charge_sum?: number;
    created_by?: number;
    modified_by?: number;
    ren_first_name: string;
    ren_last_name: string;
    ren_prov_npi: string;
    ren_prov_taxonomy_code: string;
    sbr_policy_group: string;
    secondary_sbr_id: string;
    bill_phone: string;
    last_claim_history_event: ClaimHistoryEventSerializer;
    last_other_eligibility_request: InsuranceEligibility;
    last_primary_eligibility_request: InsuranceEligibility;
    primary_payer_alt_name: PayerAltNameDetailSerializer;
    secondary_payer_alt_name: PayerAltNameDetailSerializer;
    remits: Remittance[];
    changes: Claim;
    source_claim: ClaimSimpleSerializer;
    replaced_by: ClaimSimpleSerializer;

    static viewSet = APIService.ClaimViewSet;

    @ModelBaseListAccessor(ClaimItem) items: ClaimItem[];

    @GetterCache()
    get sourceClaim$() {
        const id = getId(this.source_claim);
        return id ? Claim.retrieve(id).pipe(shareReplay(1)) : of(null);
    }

    @GetterCache()
    get diagCodeIndexRange() {
        return [...Array(this.diag_codes.length).keys()];
    }

    @GetterCache()
    get isEditable() {
        return !this.replaced_by && ['DRAFT', 'READY_TO_BILL', 'HOLD_FOR_FUTURE', 'REJECTED'].includes(this.status);
    }

    @GetterCache()
    get isCloneable() {
        return !this.replaced_by && [
            'DENIED_MISSING_INFO',
            'DENIED_INCORRECT_PAYER',
            'DENIED_INCORRECT_POS',
            'DENIED_MANAGED_CARE',
        ].includes(this.status);
    }

    @GetterCache()
    get statusColor() {
        return this.items?.[0]?.statusColor || Claim.getStatusColor(this.status);
    }

    @GetterCache()
    get statusColorClass() {
        return Claim.getStatusColorClass(this.status);
    }

    get statusText() {
        return this.items?.[0]?.statusText || Claim.getStatusText(this.status);
    }

    @GetterCache()
    get placeOfService() {
        if (this.items?.[0]?.pos) {
            return `${this.items[0].pos} - ${PLACE_OF_SERVICE_CONSTANTS.PlaceOfService[this.items[0].pos]}`;
        }
    }

    @GetterCache()
    get patientNameFields() {
        return {
            last_name: this.pat_last_name,
            first_name: this.pat_first_name,
            middle_name: this.pat_middle_name,
            name_suffix: this.pat_suffix,
        };
    }

    @GetterCache()
    get pat_name() {
        return this.getName('pat');
    }

    @GetterCache()
    get sbr_name() {
        return this.getName('sbr');
    }

    @GetterCache()
    get ren_name() {
        return this.getName('ren');
    }

    @GetterCache()
    get pat_address_full() {
        return this.getAddress('pat');
    }

    @GetterCache()
    get sbr_address_full() {
        return this.getAddress('sbr');
    }

    @GetterCache()
    get bill_address_full() {
        return this.getAddress('bill');
    }

    @GetterCache()
    get facilityAddress() {
        return this.facility && getMultilineFullAddress({
            address: this.facility.address,
            city: this.facility.city,
            state: this.facility.state,
            zip: this.facility.zip_code,
        });
    }

    @GetterCache()
    get levelOfCare() {
        return this.tasks?.[0]?.level_of_care;
    }

    @GetterCache()
    get goalsOfCare() {
        const key = this.tasks?.[0]?.history_of_present_illness?.goals_of_care;
        return key && HistoryOfPresentIllness.find(x => x.key === 'goals_of_care').choices.find(x => x.key === key)?.label;
    }

    @GetterCache()
    get sbrMatchesPat() {
        return this.pat_name === this.sbr_name &&
            this.pat_birthdate === this.sbr_birthdate &&
            this.pat_sex === this.sbr_sex &&
            this.pat_address_full === this.sbr_address_full;
    }

    @GetterCache()
    get icdActions() {
        return this.tasks?.[0]?.assessment_plan?.actions?.filter(a => a.icd?.name && EntryAction.hasCarePlan(a));
    }

    get hasTask() {
        return this.tasks?.[0];
    }
    get visibleWarnings(): string[] {
        return this.isEditable && this.invalidity_reasons;
    }

    get visibleInsuranceWarning() {
        const eligibility = this.last_primary_eligibility_request;
        return this.isEditable && eligibility?.response_status !== 'ACTIVE_COVERAGE' && eligibility;
    }

    get hasWarnings() {
        return this.visibleWarnings?.length || this.visibleInsuranceWarning;
    }

    assign(claim: ClaimSerializer) {
        super.assign(claim);
        if (this.patient?.insurance) this.patient.insurance = new PatientInsurance(this.patient.insurance);
        if (claim.last_primary_eligibility_request) this.last_primary_eligibility_request = new InsuranceEligibility(claim.last_primary_eligibility_request);
        if (claim.last_other_eligibility_request) this.last_other_eligibility_request = new InsuranceEligibility(claim.last_other_eligibility_request);
        this.changes = this.changes && Object.values(this.changes).some(v => v !== null) ? new Claim(this.changes) : null;
        this.setListAccessor('remits', Remittance);
    }

    static getStatusColor(status: ClaimStatus) {
        switch (status) {
            case 'DRAFT':
                return contextColors.warning;
            case 'HOLD_FOR_FUTURE':
                return contextColors.info;
            case 'READY_TO_BILL':
                return getStateColor(1/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'QUEUED_FOR_SUBMISSION':
                return getStateColor(2/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'UPLOADED':
                return getStateColor(3/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'ACCEPTED':
                return getStateColor(4/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'PENDING':
                return getStateColor(5/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'COMPLETED':
                return getStateColor(6/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'ALLOWED':
                return getStateColor(9/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'PARTIALLY_PAID':
                return getStateColor(10/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'PAID_BY_PRIMARY':
                return getStateColor(11/12, [{state: 0, color: contextColors.warning}, {state: 1, color: contextColors.success}]);
            case 'PAID':
            case 'OVERPAID':
                return contextColors.success;
            case 'ERROR':
            case 'REJECTED':
            case 'DENIED_MISSING_INFO':
            case 'DENIED_ENROLLED_IN_HOSPICE':
            case 'DENIED_INCORRECT_PAYER':
            case 'DENIED_INCORRECT_POS':
            case 'DENIED_MANAGED_CARE':
            case 'DENIED_TRANSFER_TO_OTHER_PAYER':
            case 'DENIED':
            case 'UNBILLABLE':
                return contextColors.danger;
            default:
                return '';
        }
    }

    static getStatusColorClass(status: ClaimStatus) {
        switch (status) {
            case 'DRAFT':
                return 'warning';
            case 'HOLD_FOR_FUTURE':
            case 'READY_TO_BILL':
            case 'QUEUED_FOR_SUBMISSION':
            case 'UPLOADED':
            case 'ACCEPTED':
            case 'PENDING':
                return 'info';
            case 'COMPLETED':
            case 'ALLOWED':
            case 'PARTIALLY_PAID':
            case 'PAID_BY_PRIMARY':
            case 'OVERPAID':
            case 'PAID':
                return 'success';
            case 'ERROR':
            case 'REJECTED':
            case 'DENIED_MISSING_INFO':
            case 'DENIED_ENROLLED_IN_HOSPICE':
            case 'DENIED_INCORRECT_PAYER':
            case 'DENIED_INCORRECT_POS':
            case 'DENIED_MANAGED_CARE':
            case 'DENIED_TRANSFER_TO_OTHER_PAYER':
            case 'DENIED':
            case 'UNBILLABLE':
                return 'danger';
            default:
                return '';
        }
    }

    static getIdentifier(x) {
        return `BST${x.id}`;
    }

    static getStatusText(status: ClaimStatus) {
        return CLAIM_STATUSES[status];
    }

    @MethodCache()
    getName(prefix: 'pat' | 'sbr' | 'ren' = 'pat') {
        return Human.getName({
            last_name: this[`${prefix}_last_name`],
            first_name: this[`${prefix}_first_name`],
            middle_name: this[`${prefix}_middle_name`],
            name_suffix: this[`${prefix}_suffix`],
        }) || undefined;
    }

    @MethodCache()
    getAddress(prefix: 'pat' | 'sbr' | 'bill') {
        return getMultilineFullAddress({
            address: this[`${prefix}_address`] || this[`${prefix}_add1`],
            city: this[`${prefix}_city`],
            state: this[`${prefix}_state`],
            zip: this[`${prefix}_zip`],
        }) || undefined;
    }

    @MethodCache()
    getSum(key: NumberKeys<ClaimItem>): number {
        if (this.items.every(x => [null, undefined].includes(x[key]))) return null;
        return this.items.reduce((acc, x) => acc + (x[key] || 0), 0);
    }
}
